import React, { Component } from 'react';



class Floors extends Component {
    constructor(props) {
      super(props);
    }

    render(){
      
        let rows=[];
        let nRows=this.props.floors;
        for(let i=1; i <= nRows; i++){
              rows.push(<li class="small"><span class=""><span class="pull-left ">{i}&nbsp;&nbsp;</span>Floor buildup area [{this.props.sba} {this.props.areaUnitsPrefix}{this.props.unitText}] =</span><span class="pull-right" ><i class="fa fa-inr" aria-hidden="true"></i>{this.props.numberWithCommas(this.props.ecvf)}</span></li>);
        }
        
        return <div class="row d-flex">
        <div class="col-12">
        <ul class="list-unstyled">
        {rows}
        </ul>
        </div>
        </div>
      
    }

}

export default Floors;

//Floors {this.props.floors} SBA {this.props.sba}  ECVF {this.props.ecvf}


    // rows.push(<span> {i} Floor buildup area  [{this.props.sba} {this.props.areaUnitsPrefix}{this.props.unitText}]  = <span> <i class="fa fa-inr" aria-hidden="true"></i></span><br/></span>);
            // rows.push(<li class="small"><span class="">{i} Floor buildup area [{this.props.sba} {this.props.areaUnitsPrefix}{this.props.unitText}] =</span><span class="pull-right" ><i class="fa fa-inr" aria-hidden="true"></i>{this.props.numberWithCommas(this.props.ecvf)}</span></li>);
      