import { Popover } from 'bootstrap';
import React, { Component } from 'react';
import Floors from '../Floors/Floors';

// import HomePackagesList from '../HomePackagesList/HomePackagesList';
class Estimator extends Component {
    constructor(props) {
      super(props);
      this.state = {iteration:0,estimatedValue2:0.00,estimatedValue3:0.00,estimatedValue5:0.00,estimatedValue7:0.00,
                    constants:{SCRFSP:0.12,SCRSSP:0.08,SCPA:135,CPCVP:0.7,CCCR2S:1700,CCCR3S:2000,CCCR5S:2500.00,CCCR7S :2800.00},
                    currency:1,city:1,model:1,floors:0,carParking:0,totalArea:1200.00,plotSize:1,frontage:30,depth:40,unit:1,unitText:'ft',areaUnitsPrefix:'sq',
                    homepackages : [],validationError:'',visible:false,CRFL:0.00,CRBL:0.00,CRFLA:0.00,CRSLA:0.00,CRSAH:0.00,SBA:0.00,BAG:0.00,
                    mobile:'',email:'',emailSection:false,valid:true,validationText:'',captureSize:false,capSection:true,
                  ECVG2:0.00,ECVG3:0.00,ECVG5:0.00,ECVG7:0.00,ECVF2:0.00,ECVF3:0.0,ECVF5:0.00,ECVF7:0.0};

      this.currencies=[
                      {key:1,name:'Rupee',currency:'INR',symbol:'₹',factor:1.00},
                      {key:2,name:'US Dollars',currency:'USD',symbol:'$',factor:73.15}
                      ];
      
      this.units=['Feets','Meters','Inches','Yards'];

      this.cities=[
                    {value:1,name:'Bangalore',SCRFSP:0.12,SCRSSP:0.08,SCPA:135,CCCR2S:0.00,CCCR3S:0.00,CCCR5S:0.00,CCCR7S:0.00},
                    {value:2,name:'Mysore',SCRFSP:0.12,SCRSSP:0.08,SCPA:135,CCCR2S:0.00,CCCR3S:0.00,CCCR5S:0.00,CCCR7S:0.00},
                    {value:3,name:'Shimogga',SCRFSP:0.12,SCRSSP:0.08,SCPA:135,CCCR2S:0.00,CCCR3S:0.00,CCCR5S:0.00,CCCR7S:0.00},
                    {value:4,name:'Davangere',SCRFSP:0.12,SCRSSP:0.08,SCPA:135,CCCR2S:0.00,CCCR3S:0.00,CCCR5S:0.00,CCCR7S:0.00},
                  ];
      
      this.models=[
                    {value:1,name:'Residential'},
                    {value:2,name:'Villa'},
                    {value:3,name:'Farm House'},
                    {value:4,name:'Apartments'},
                    {value:5,name:'Commercial Cum Residential'},
                    {value:6,name:'Cellar Parking Cum Residential'},
                    {value:7,name:'Cellar Parking, Commercial Ground Floor and Residential'},
                  ];

      // this.formValues = {city:'',model:'',plotSize:1,totalArea:0.0,floors:0,carParking:0,frontage:0.0,unit:'Feets'};
      this.history=[];
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleChange =this.handleChange.bind(this);
      this.numberWithCommas = this.numberWithCommas.bind(this);
     
    }
  
    // getData() {
    //   return this.state;
    // }
  // numberWithCommas(x) {
  //     return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  // }
  //Intl.NumberFormat('en-IN', { style:'currency', currency:'INR', maximumSignificantDigits: 3 }).format(x);
  numberWithCommas(x) {
    return new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 4 }).format(x);
  }
render() {
return (        
  <div>
    <section class="position-relative py-5" id="costestimator-section">
      <div class="container">
        <div class="row" style={{marginTop:"20px"}}>
          <div class="col-12 text-center pt-3 pb-5" >
          <div class="row">
            {/* <img src="image/bgpaint.png" className="cost-header-image" /> */}
            
            {/* <div class="col-12 w100 d-flex justify-content-center">
            <div class="section-title">Cost Estimator</div>
            </div> */}
            {/* <div className="col-4"></div> */}
              <div className="  cost-estimator-header col-12 w100 d-flex justify-content-center">
              <h1>Cost Estimator</h1>
              </div>
              {/* <div className="col-4"></div> */}
            </div>
          </div>
          <div class="col-12 col-sm-12">
              <p>Enter your site and your dream home details to get an estimation</p>
              </div>
          <div class="col-12 col-sm-1"></div>
          <div class="col-12 col-sm-10 p-5 formwrapper" >
            <form class="form-bg" onSubmit={this.handleSubmit} id="estimatorForm" name="estimatorForm">
              <div class="row form-group">
              
                <div class="col-12 col-sm-12">
                  <div class="row">
                    <div class="col-12 col-sm-6 py-2">
                      <label for="selectcity" class="form-label">Select City</label>
                      <select id="yourcity" class="custom-select" aria-label="select city"
                        value={this.state.city}
                        onChange={e => {
                          this.setState({
                            city: e.target.value,
                            validationError:
                              e.target.value === ""
                                ? "You must select your city"
                                : ""
                          });
                          this.handleChange(e);
                        }
                        }>
                        {this.cities.map(city => (
                          <option
                            key={city.value}
                            value={city.value}
                          > {city.name}</option>))
                        }
                      </select>
                      <span
                        style={{
                          color: "red",
                          marginTop: "5px"
                        }}>
                        {this.state.validationError}
                      </span>
                    </div>
                    <div class="col-12 col-sm-6 py-2">
                      <label for="yourmodel" class="form-label">Select Model</label>
                      <select id="yourmodel" class="custom-select" aria-label="Select Model"
                        value={this.state.model}
                        onChange={e => {
                          this.setState({
                            model: e.target.value,
                            capSection: (e.target.value == 4 || e.target.value == 5 || e.target.value == 6 || e.target.value == 7 ? false : true),
                            emailSection: (e.target.value == 4 || e.target.value == 5 || e.target.value == 6 || e.target.value == 7 ? true : false)
                          });
                          this.handleChange(e);
                        }
                        }>
                        {
                          this.models.map(model => (
                            <option
                              key={model.value}
                              value={model.value}
                            > {model.name}</option>))
                        }
                      </select>
                      <div
                        style={{
                          color: "red",
                          marginTop: "5px"
                        }}>
                        {this.state.validationError}
                      </div>
                    </div>

                    <div className={"col-12 col-sm-6 py-2 " + (this.state.capSection ? 'show' : 'hide')}>
                      <div class="row">
                        <div class="col-12 col-6">
                          <label for="sitedimention">Site Dimensions &nbsp;&nbsp;</label>
                          <div class="plot ">
                            <select class="custom-select" aria-label="Select Site Dimensions" data-toggle="tooltip" data-placement="right" title="Select Your Site Frontage x Depth"
                            value={this.state.plotSize}
                              onChange={e => {
                                switch (e.target.value) {
                                  case "0": { this.setState({ plotSize: 0, frontage: 10, depth: 10, totalArea: 100.00, captureSize: false }) } break;
                                  case "1": { this.setState({ plotSize: 1, frontage: 30, depth: 40, totalArea: 1200.00, captureSize: false }) } break;
                                  case "2": { this.setState({ plotSize: 2, frontage: 60, depth: 40, totalArea: 2400.00, captureSize: false }) } break;
                                  case "3": { this.setState({ plotSize: 3, frontage: 90, depth: 120, totalArea: 10800.00, captureSize: false }) } break;
                                  case "4": { this.setState({ plotSize: 4, frontage: 10, depth: 10, totalArea: 100.00, captureSize: true }); } break;
                                };
                                this.setState({
                                  [e.target.getAttribute('data-content')]: "Enter Total Area and Frontage",
                                  [e.target.getAttribute('title')]: "Odd Site",
                                });
                                this.handleChange(e);
                              }
                              }>
                              <option value="0">Select Plot Size</option>
                              <option value="1">30 x 40</option>
                              <option value="2">60 x 40</option>
                              <option value="3">90 x 120</option>
                              <option value="4">Odd or Any Other Size</option>
                            </select>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={"col-12 col-sm-6 py-2 " + (this.state.capSection ? 'd-flex' : 'd-none')}>
                      <div class="row px-1 plot " >
                        <div class="col-12 col-sm-4 form-check">
                          <label for="frontage">Frontage</label>
                          <input type="number" class="form-control" id="frontage" min="10" name="frontage" aria-describedby="frontage" aria-label="Frontage " readOnly={this.state.captureSize === false ? true : false}
                            value={this.state.frontage}
                            onChange={e => {
                              this.setState(
                                {
                                  frontage: e.target.value,
                                  totalArea: this.state.depth * e.target.value,
                                });
                              //this.state.validationError=(e.target.value<=0)?"*":''   ;
                              this.handleChange(e);
                            }
                            }
                            data-toggle="tooltip" data-placement="top" title="Enter Site Frontage or Width" />
                          <span
                            style={{
                              color: "red",
                              marginTop: "5px",
                              fontSize: "25px"
                            }}>
                            {this.state.validationError}
                          </span>
                        </div>
                        <div class="col-12 col-sm-5 px-1 form-check" >
                          <label htmlFor="depth">Depth</label>
                          <div class="input-group mb-3">

                            <input type="number" class="form-control rounded" aria-label="Depth " id="depth" name="depth" min="10" aria-describedby="depth" readOnly={this.state.captureSize === false ? true : false}
                              value={this.state.depth}
                              onChange={e => {
                                this.setState(
                                  {
                                    depth: e.target.value,
                                    totalArea: this.state.frontage * e.target.value
                                  });
                                this.handleChange(e);
                                // this.state.validationError=(e.target.value<=0)?"*":''   ;
                              }
                              }
                              data-toggle="tooltip" data-placement="top" title="Enter Site Depth or Length" />
                            <span
                              style={{
                                color: "red",
                                marginTop: "5px",
                                fontSize: "25px"
                              }}>
                              {this.state.validationError}
                            </span>
                            <div class="input-group-append">
                              <button class="btn border-0 bg-transperent " onClick={e => {
                                this.setState({
                                  frontage: this.state.depth,
                                  depth: this.state.frontage
                                })
                              }}>
                                <i class="fa fa-refresh"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-3 px-1 form-check ">
                        <label htmlFor="totalarea" class="form-label">Total Area</label>
                        <input type="text" class="form-control" disabled id="totalarea" aria-label="Total Area "
                          value={this.state.totalArea}
                          onChange={e =>
                            this.setState(
                              { totalArea: e.target.value })
                          }
                        />
                      </div>
                      </div>
                    
                    </div>
                    <div className={"col-12 col-sm-6 py-2 " + (this.state.capSection ? 'show' : 'hide')}>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div class="input-group-text">
                            <label class="pl-2 mb-0">Ground + </label>
                          </div>
                        </div>
                        <input type="number" class="form-control " aria-label="" step="1" class="form-control" id="floors" name="floors"
                          max="5" min="0"
                          value={this.state.floors}
                          onChange={e => {
                            this.setState(
                              { floors: e.target.value });
                            this.handleChange(e);
                          }
                          }
                          data-toggle="tooltip" data-placement="top" title="Enter Number of required additional Floors"
                        />
                        <div class="input-group-append">
                          <div class="input-group-text">
                            <label class="pl-2 mb-0">Floors</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={"col-12 col-sm-6 py-2 " + (this.state.capSection ? 'show' : 'hide')}>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div class="input-group-text">
                            <label class="pl-2 mb-0">Car Parking</label>
                          </div>
                        </div>
                        <input type="number" class="form-control " aria-label="" step="1" class="form-control" id="cars" name="cars"
                          max="5" min="0"
                          value={this.state.carParking}
                          onChange={e => {
                            this.setState(
                              { carParking: e.target.value });
                            this.handleChange(e);
                          }
                          }
                          data-toggle="tooltip" data-placement="top" title="Enter Number of Vehicle Parking required"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 py-2">
                          {
                            (this.state.totalArea<=800)?<a href="#">Buy Floor Plans</a>:"" 
                          }
                    </div>
                    {(this.state.emailSection === true) ?
                      <div class="col-12 col-sm-6 py-2">
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="email"><i class="fa fa-at"></i></span>
                          </div>
                          <input type="email" class="form-control" aria-label="" placeholder="Your mail id" class="form-control" id="email" name="email"
                            value={this.state.email}
                            onChange={e =>
                              this.setState(
                                { email: e.target.value })
                            }
                          />
                        </div>

                      </div>
                      : ""}
                    {(this.state.emailSection === true) ?
                      <div class="col-12 col-sm-6 py-2">
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="mobile"><i class="fa fa-whatsapp"></i></span>
                          </div>
                          <input type="phone" class="form-control" aria-label="" placeholder="Your WhatsApp Number" class="form-control" id="mobile" name="mobile"
                            value={this.state.mobile}
                            onChange={e =>
                              this.setState(
                                { mobile: e.target.value })
                            }
                          />
                        </div>
                      </div> : ""
                    }
                    <div class="col-12 col-sm-12 py-2">
                      <p className="pull-right"><a href="#" onClick={e => {
                        e.preventDefault();
                        this.setState({ emailSection: !this.state.emailSection });
                      }
                      }>{this.state.emailSection ? (this.state.capSection ? 'Just Display ':'') : 'Get It By Email'} </a> {this.state.emailSection === true ? '' : 'or'} <a href="#" onClick={e => {
                        e.preventDefault();
                        this.setState({ emailSection: !this.state.emailSection });
                      }
                      }>{this.state.emailSection ? '' : ' WhatsApp'}</a></p>
                    </div>
                    {
                      (this.state.valid === false) ?
                        <div class="col-12 col-sm-12 py-2" style={{
                          color: "red",
                          marginTop: "5px"
                        }}
                        >
                          {/* {ReactHtmlParser(this.state.validationText)} */}
                        </div>
                        : ''
                    }
                  </div>
                </div>
              </div>
              <div class="text-center pt-3 ">
                <button id="estimate-btn" class="btn" ><span>{(this.state.capSection ? "Estimate Now" : "Submit")} </span></button>
              </div>
            </form>
          </div>
        </div>
        <div class="col-12 col-sm-1"></div>
      </div>
    </section>
    

   

    {/* <section className={(this.state.visible) ? 'show' : 'hide'}>
      Result {this.state.estimatedValue2} <br />
            City {this.state.city}<br />
            Model {this.state.model}<br />
            Car {this.state.carParking}<br />
            Floors{this.state.floors}<br />
            SCRFSP {this.state.constants.SCRFSP}<br />
            SCRSSP {this.state.constants.SCRSSP}<br />
            SCPA {this.state.constants.SCPA}<br />
            CPCVP {this.state.constants.CPCVP}<br />
            CCCR2S {this.state.constants.CCCR2S}<br />
            CCCR3S {this.state.constants.CCCR3S}<br />
            CCCR5S {this.state.constants.CCCR5S}<br />
            CCCR7S {this.state.constants.CCCR7S}<br />
            Total Area {this.state.totalArea}<br />
            SSLorDepth {this.state.totalArea / this.state.frontage}<br />
            CRFL={this.state.CRFL}<br />
            CRBL=CRSL {this.state.CRBL}<br />
            CRFLA=  {this.state.CRFLA}<br />
            CRSLA= {this.state.CRSLA} <br />
            CRSAH = CRSLA + CRFLA {this.state.CRFLA + this.state.CRSLA} <br />
            SBA = SFW * SSLorDepth - CRSAH;{this.state.SBA}  <br />
            BAG = {this.state.BAG} <br />
            TCPCV2 = CPN * SCPA *CCCR2S *CPCVP {this.state.carParking * this.state.constants.SCPA * this.state.constants.CCCR2S * this.state.constants.CPCVP}<br />
            Iteration #{this.state.iteration}<br />
            Estimated Value for 2* {this.state.estimatedValue2}<br />
            Estimated Value for 3* {this.state.estimatedValue3}<br />
            Estimated Value for 5* {this.state.estimatedValue5}<br />
            Estimated Value for 7* {this.state.estimatedValue7}<br />
    </section> */}

    <section className={"m-0 px-3 py-5 packages "+ (this.state.visible ? 'show' : 'hide')} style={{textAlign:"left"}} > 
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-sm-3 py-3">
                        <div class="card h-100 package-card-bg">
                            <div class="card-header m-2 text-center text-white two-package-header">
                                <p class="client-review-stars">
                                    <i class="fa fa-star"></i>
                                    <i class="fa fa-star"></i>
                                </p>
                                <h6>{this.state.constants.CCCR2S}<span>/{this.state.areaUnitsPrefix}{this.state.unitText}</span></h6>
                            </div>
                            <div class="card-body">
                                <div class="row d-flex">
                                    <div class="col-12">
                                        <div class="row d-flex">
                                            <div class="col-6 ">
                                                <h6 class=" display-5">Ground Floor</h6>
                                            </div>
                                             <div class="col-6 text-right">
                                                 <h6 class=" display-5"><i class="fa fa-inr" aria-hidden="true"></i>{this.numberWithCommas(this.state.ECVG2)}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row d-flex">
                                    <div class="col-12">
                                                <ul class="list-unstyled">
                                                    <li class="small"><span class="pull-left">Total buildup[{this.state.SBA} {this.state.areaUnitsPrefix}{this.state.unitText}]</span></li>
                                                    <li class="small"><span class="pull-left">{(this.state.carParking>0?<span>(-)Car Projection[{this.state.constants.SCPA} {this.state.areaUnitsPrefix}{this.state.unitText}]</span>:"")}</span></li>
                                                    <li class="small"><span class="pull-left">(=)Actual buildup[{(this.state.carParking>0?(this.state.SBA-(this.state.constants.SCPA*this.state.carParking)):(this.state.SBA))} {this.state.areaUnitsPrefix}{this.state.unitText}]</span></li>
                                                </ul>
                                    </div>
                                </div>
                                
                                <div class="row d-flex">
                                    <div class="col-12">
                                        
                                        <div class="row d-flex">
                                            <div class="col-6 "  style={{marginTop:"10px"}}>
                                                <h6 class=" display-5">Ground Floor</h6>
                                            </div>
                                             <div class="col-6 text-right">
                                                 <h6 class=" display-5"><i class="fa fa-inr" aria-hidden="true"></i>{this.numberWithCommas(this.state.ECVG2)}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Floors floors={this.state.floors} sba={this.state.SBA} areaUnitsPrefix={this.state.areaUnitsPrefix} unitText={this.state.unitText} ecvf={this.state.ECVF2}  numberWithCommas={this.numberWithCommas}/>              
							</div>
							<div class="total-bg  p-2">
                                <div class="row d-flex">
                                    <div class="col-6 ">
                                        <h5 class="text-left mb-0">Total Cost</h5>
                                    </div>
                                     <div class="col-6 text-right">
                                         <h5 class=" mb-0"><i class="fa fa-inr" aria-hidden="true"></i>{this.numberWithCommas(this.state.estimatedValue2)}</h5>
                                    </div>
                                </div>
								
                            </div>
                            <div class="row d-flex pt-3">
                                <div class="col-12 text-center pt-3">
                                            <h6 class=" display-5">Total buildup area:<span>{(this.state.floors * this.state.SBA)+(this.state.BAG)} {this.state.areaUnitsPrefix}{this.state.unitText}</span></h6>
                                             <h6 class=" display-5">Cost calcluated @ <i class="fa fa-inr" aria-hidden="true"></i> {this.state.constants.CCCR2S} </h6>
                                </div>
                            </div>
                            <div class="card-footer two-package-border " ></div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-3 py-3">
                        <div class="card h-100 package-card-bg">
                            <div class="card-header text-center text-white m-2 three-package-header" >
                                <p class="client-review-stars">
                                    <i class="fa fa-star"></i>
                                    <i class="fa fa-star"></i>
                                    <i class="fa fa-star"></i>
                                </p>
                                <h6>{this.state.constants.CCCR3S}<span>/{this.state.areaUnitsPrefix}{this.state.unitText}</span></h6>
                            </div>
                            <div class="card-body">
                                <div class="row d-flex">
                                    <div class="col-12">
                                        
                                        <div class="row d-flex">
                                            <div class="col-6 ">
                                                <h6 class=" display-5">Ground Floor</h6>
                                            </div>
                                             <div class="col-6 text-right">
                                                 <h6 class=" display-5"><i class="fa fa-inr" aria-hidden="true"></i>{this.numberWithCommas(this.state.ECVG3)}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p>
                                <span>Total buildup({this.state.SBA} {this.state.areaUnitsPrefix}{this.state.unitText})</span><br/>
                                    {(this.state.carParking>0?<span>(-)Car Projection({this.state.constants.SCPA} {this.state.areaUnitsPrefix}{this.state.unitText})</span>:"")}
                                    <br/>
                                    <span>(=)Actual buildup[{(this.state.carParking>0?(this.state.SBA-(this.state.constants.SCPA*this.state.carParking)):(this.state.SBA))} {this.state.areaUnitsPrefix}{this.state.unitText}]</span>
                                    <br/>
                                   
                                </p>
                                <div class="row d-flex">
                                    <div class="col-12">
                                        
                                        <div class="row d-flex">
                                            <div class="col-6 ">
                                                <h6 class=" display-5">Ground Floor</h6>
                                            </div>
                                             <div class="col-6 text-right">
                                                 <h6 class=" display-5"><i class="fa fa-inr" aria-hidden="true"></i>{this.numberWithCommas(this.state.ECVG3)}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Floors floors={this.state.floors} sba={this.state.SBA} areaUnitsPrefix={this.state.areaUnitsPrefix} unitText={this.state.unitText} ecvf={this.state.ECVF3} numberWithCommas={this.numberWithCommas}/>
							</div>
							<div class="total-bg  p-2">
                                <div class="row d-flex">
                                    <div class="col-6 ">
                                        <h5 class="text-left mb-0">Total Cost</h5>
                                    </div>
                                     <div class="col-6 text-right">
                                         <h5 class=" mb-0"><i class="fa fa-inr" aria-hidden="true"></i>{this.numberWithCommas(this.state.estimatedValue3)}</h5>
                                    </div>
                                </div>
								
                            </div>
                            <div class="row d-flex pt-3">
                                <div class="col-12 text-center pt-3">
                                            <h6 class=" display-5">Total buildup area:<span>{(this.state.floors * this.state.SBA)+(this.state.BAG)} {this.state.areaUnitsPrefix}{this.state.unitText}</span> </h6>
                                             <h6 class=" display-5">Cost calcluated @ <i class="fa fa-inr" aria-hidden="true"></i> {this.state.constants.CCCR3S} </h6>
                                </div>
                            </div>
                            <div class="card-footer three-package-border " ></div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-3 py-3">
                        <div class="card h-100 package-card-bg">
                            <div class="card-header text-center text-white m-2 five-package-header" >
                                <p class="client-review-stars">
                                    <i class="fa fa-star"></i>
                                    <i class="fa fa-star"></i>
                                    <i class="fa fa-star"></i>
                                    <i class="fa fa-star"></i>
                                    <i class="fa fa-star"></i>
                                </p>
                                <h6>{this.state.constants.CCCR5S}<span>/{this.state.areaUnitsPrefix}{this.state.unitText}</span></h6>
                            </div>
                            <div class="card-body">
                                <div class="row d-flex">
                                    <div class="col-12">
                                        
                                        <div class="row d-flex">
                                            <div class="col-6 ">
                                                <h6 class=" display-5">Ground Floor</h6>
                                            </div>
                                             <div class="col-6 text-right">
                                                 <h6 class=" display-5"><i class="fa fa-inr" aria-hidden="true"></i>{this.numberWithCommas(this.state.ECVG5)}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p>
                                <span>Total buildup({this.state.SBA} {this.state.areaUnitsPrefix}{this.state.unitText})</span><br/>
                                    {(this.state.carParking>0?<span>(-)Car Projection({this.state.constants.SCPA} {this.state.areaUnitsPrefix}{this.state.unitText})</span>:"")}
                                    <br/>
                                    <span>(=)Actual buildup[{(this.state.carParking>0?(this.state.SBA-(this.state.constants.SCPA*this.state.carParking)):(this.state.SBA))} {this.state.areaUnitsPrefix}{this.state.unitText}]</span>
                                    <br/>
                                   
                                </p>
                                <div class="row d-flex">
                                    <div class="col-12">
                                        
                                        <div class="row d-flex">
                                            <div class="col-6 ">
                                                <h6 class=" display-5">Ground Floor</h6>
                                            </div>
                                             <div class="col-6 text-right">
                                                 <h6 class=" display-5"><i class="fa fa-inr" aria-hidden="true"></i>{this.numberWithCommas(this.state.ECVG5)}</h6>
                                            </div>
                                        </div>
                                    </div>
                                
                                
                                
                                </div>
                                <Floors floors={this.state.floors} sba={this.state.SBA} areaUnitsPrefix={this.state.areaUnitsPrefix} unitText={this.state.unitText} ecvf={this.state.ECVF5} numberWithCommas={this.numberWithCommas}/>
							</div>
							<div class="total-bg  p-2">
                                <div class="row d-flex">
                                    <div class="col-6 ">
                                        <h5 class="text-left mb-0">Total Cost</h5>
                                    </div>
                                     <div class="col-6 text-right">
                                         <h5 class=" mb-0"><i class="fa fa-inr" aria-hidden="true"></i>{this.numberWithCommas(this.state.estimatedValue5)}</h5>
                                    </div>
                                </div>
								
                            </div>
                            <div class="row d-flex pt-3">
                                <div class="col-12 text-center pt-3">
                                    <h6 class=" display-5">Total buildup area:<span>{(this.state.floors * this.state.SBA)+(this.state.BAG)} {this.state.areaUnitsPrefix}{this.state.unitText}</span> </h6>
                                    <h6 class=" display-5">Cost calcluated @ <i class="fa fa-inr" aria-hidden="true"></i> {this.state.constants.CCCR5S} </h6>
                                </div>
                            </div>
                            <div class="card-footer five-package-border " ></div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-3 py-3">
                        <div class="card h-100 package-card-bg">
                            <div class="card-header text-center text-white m-2 seven-package-header">
                                <p class="client-review-stars">
                                    <i class="fa fa-star"></i>
                                    <i class="fa fa-star"></i>
                                    <i class="fa fa-star"></i>
                                    <i class="fa fa-star"></i>
                                    <i class="fa fa-star"></i>
                                    <i class="fa fa-star"></i>
                                    <i class="fa fa-star"></i>
                                </p>
                                <h6>{this.state.constants.CCCR7S}<span>/{this.state.areaUnitsPrefix}{this.state.unitText}</span></h6>
                            </div>
                            <div class="card-body">
                                <div class="row d-flex">
                                    <div class="col-12">
                                        
                                        <div class="row d-flex">
                                            <div class="col-6 ">
                                                <h6 class=" display-5">Ground Floor</h6>
                                            </div>
                                             <div class="col-6 text-right">
                                                 <h6 class=" display-5"><i class="fa fa-inr" aria-hidden="true"></i>{this.numberWithCommas(this.state.ECVG7)}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p>
                                <span>Total buildup({this.state.SBA} {this.state.areaUnitsPrefix}{this.state.unitText})</span><br/>
                                    {(this.state.carParking>0?<span>(-)Car Projection({this.state.constants.SCPA} {this.state.areaUnitsPrefix}{this.state.unitText})</span>:"")}
                                    <br/>
                                    <span>(=)Actual buildup[{(this.state.carParking>0?(this.state.SBA-(this.state.constants.SCPA*this.state.carParking)):(this.state.SBA))} {this.state.areaUnitsPrefix}{this.state.unitText}]</span>
                                    <br/>
                                </p>
                                <div class="row d-flex">
                                    <div class="col-12">
                                        
                                        <div class="row d-flex">
                                            <div class="col-6 ">
                                                <h6 class=" display-5">Ground Floor</h6>
                                            </div>
                                             <div class="col-6 text-right">
                                                 <h6 class=" display-5"><i class="fa fa-inr" aria-hidden="true"></i>{this.numberWithCommas(this.state.ECVG7)}</h6>
                                            </div>
                                        </div>
                                    </div>
                                
                                
                                
                                </div>
                                <Floors floors={this.state.floors} sba={this.state.SBA} areaUnitsPrefix={this.state.areaUnitsPrefix} unitText={this.state.unitText} ecvf={this.state.ECVF7} numberWithCommas={this.numberWithCommas}/>
							</div>
							<div class="total-bg  p-2">
                                <div class="row d-flex">
                                    <div class="col-6 ">
                                        <h5 class="text-left mb-0">Total Cost</h5>
                                    </div>
                                     <div class="col-6 text-right">
                                         <h5 class=" mb-0"><i class="fa fa-inr" aria-hidden="true"></i>{this.numberWithCommas(this.state.estimatedValue7)}</h5>
                                    </div>
                                </div>
								
                            </div>
                            <div class="row d-flex pt-3">
                                <div class="col-12 text-center pt-3">
                                <h6 class=" display-5">Total buildup area:<span>{(this.state.floors * this.state.SBA)+(this.state.BAG)} {this.state.areaUnitsPrefix}{this.state.unitText}</span> </h6>
                                    <h6 class=" display-5">Cost calcluated @ <i class="fa fa-inr" aria-hidden="true"></i> {this.state.constants.CCCR7S} </h6>
                                            
                                </div>
                            </div>
                            <div class="card-footer seven-package-border " ></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

  </div>);
}


    handleChange(e){
      e.preventDefault();
      this.setState({visible:false});
    }


    handleSubmit(e) {

      e.preventDefault();
      /*Do nothing if page variables are not set */
      /*if (this.state.packages.count === 0) {
        return;
      }*/
      // const newItem = {
      //   text: this.state.text,
      //   id: Date.now()
      // };

      if (this.state.totalArea===0 || this.state.depth===0 || this.state.frontage===0)
      {
          var validationText='Please select and enter valid inputs<br/>';
          validationText+= (this.state.frontage<=0)?'Frontage value should be greater than 0':'';
          validationText+=(this.state.depth<=0)?'<br/>Depth value should be greater than 0':'';
          validationText+=(this.state.totalArea<=0)?'<br/>Total Area of the site should be greater than 0':'';
          this.setState({valid:false,validationText: validationText});
      }
      else
      {
          this.setState({valid:true,validationText:''});
      }

      if(this.state.valid===true)
      {    

      this.setState({visible:false});
      var SCRFSP=this.state.constants.SCRFSP;
      var SCRSSP=this.state.constants.SCRSSP;
      var SCPA = this.state.constants.SCPA;
      var CPCVP=this.state.constants.CPCVP;
      var CCCR2S =this.state.constants.CCCR2S;
      var CCCR3S =this.state.constants.CCCR3S;
      var CCCR5S=this.state.constants.CCCR5S;
      var CCCR7S =this.state.constants.CCCR7S;
      var SFW=this.state.frontage;
      var SSLorDepth=this.state.totalArea/SFW;
      var CRFL=SCRFSP*SSLorDepth;
      var CRSL=0.0;
      var CRBL=CRSL=SCRSSP * SFW;  
      var CRFLA= (SFW - (SSLorDepth * SCRSSP * 2 )) * CRFL;
      var CRSLA= (SSLorDepth-CRFL) * CRBL * 2; 
      var CRSAH = CRSLA + CRFLA;
     // var SBA = SFW * SSLorDepth - CRSAH;
      var SBA = Math.round((SFW-(SFW * SCRSSP * 2)) * (SSLorDepth-(SSLorDepth * (SCRSSP+SCRFSP))));
      //var CPA=CPCVP*SCPA;
      var CPN= this.state.carParking;
      var TCPCV2 = CPN * SCPA * CCCR2S * CPCVP;
      var BAG = Math.round(SBA - (CPN * SCPA));
      
      var ECVG2 = (BAG * CCCR2S)+ TCPCV2
      var ECVF2=CCCR2S * SBA;

      var TCPCV3 = CPN * SCPA * CCCR3S * CPCVP;
      var ECVG3 =( BAG * CCCR3S) + TCPCV3
      var ECVF3=CCCR3S * SBA;


      var TCPCV5 = CPN * SCPA * CCCR5S * CPCVP;
      var ECVG5 = (BAG * CCCR5S)+ TCPCV5
      var ECVF5=CCCR5S * SBA;

      var TCPCV7 = CPN * SCPA * CCCR7S * CPCVP;
      var ECVG7 = (BAG * CCCR7S) + TCPCV7
      var ECVF7=CCCR7S * SBA;

      var NF=this.state.floors;

      var ECV2= ECVG2+(ECVF2 * NF);
      var ECV3= ECVG3+(ECVF3 * NF);
      var ECV5= ECVG5+(ECVF5 * NF);
      var ECV7= ECVG7+(ECVF7 * NF);

      this.setState({
        CRFL:CRFL,
        CRBL:CRBL,
        CRFLA:CRFLA,
        CRSLA:CRSLA,
        CRSAH:CRSAH,
        BAG:BAG,
        SBA:SBA,
        
        ECVG2:ECVG2,
        ECVG3:ECVG3,
        ECVG5:ECVG5,
        ECVG7:ECVG7,

        ECVF2:ECVF2,
        ECVF3:ECVF3,
        ECVF5:ECVF5,
        ECVF7:ECVF7,
        
        estimatedValue2:ECV2,
        estimatedValue3:ECV3,
        estimatedValue5:ECV5,
        estimatedValue7:ECV7,
        iteration:this.state.iteration+1
      });

      this.setState({visible:true});
    }
    else 
    {
      
    }
  }
  }
  
  export default Estimator;

  // ReactDOM.render(
  //   <TodoApp />,
  //   document.getElementById('todos-example')
  // );