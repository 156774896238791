import React, { Component } from 'react';

class Packages extends Component {
    constructor(props) {
      super(props);

      this.state={};
    }

    render() {
        return (         
         <section class="position-relative py-5" id="packages-section" style={{textAlign:"left"}}>
            <div class="container">
                <div class="row">
                    <div class="col-12 text-center pt-3 pb-5">
                        {/* <img src="image/our-service.png" class="cost-header-image" />
                        <h2 class="header-text text-white">Our Packages</h2> */}

                        <div class="row">
         
              <div className="packages-header col-12 w100 d-flex justify-content-center">
              <h1>Packages</h1>
              </div>
             
            </div>
                    </div>
                    <div class="col-12">
                        <div class="row m-0">
                            <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 py-4">
                                <div class="bs-example">
                                    <div class="accordion" id="accordionExample">
                                        <div class="card border-0">
                                            <div class="header bg-dark py-3 position-relative overflow-hidden">
                                                <h2 class="text-white text-center font-weight-bold display-4 mb-0">
                                                    2
                                                    <img src="image/starwhite.png" class="star-icon" width="50" height="50" />
                                                </h2>
                                               
                                                <div class="clip"></div>
                                            </div>
                                            <div class="card-header p-0 border-0" id="headingOne">
                                                <h2 class="mb-0">
                                                    <button type="button" class="btn btn-link py-0 w-100 position-relative pack-btn collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false">
                                                        Design &amp; Drawings
                                                        <i class="fa fa-plus package-fa"></i>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                                <div class="card-body">
                                                    <ul>
                                                        <li>2D Floor Plan</li>
                                                        <li>Basic Elevation</li>
                                                        <li>Structural Design</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card border-0">
                                            <div class="card-header p-0 border-0" id="headingTwo">
                                                <h2 class="mb-0">
                                                    <button type="button" class="btn btn-link py-0 w-100 collapsed position-relative pack-btn" data-toggle="collapse" data-target="#collapseTwo">
                                                        Structure
                                                        <i class="fa fa-plus package-fa" ></i>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                                <div class="card-body">
                                                    <ul>
                                                        <li>
                                                            <span >Steel - Sunvik, Meenakshi, Kamdhenu or equivalent</span>
                                                        </li>
                                                        <li>
                                                            <span >Aggregates - 20mm &amp; 40mm</span>
                                                        </li>
                                                        <li>
                                                            <span >Blocks - Standard Solid Concrete blocks. 6 inch 4 inch</span>
                                                        </li>
                                                        <li>
                                                            <span >Cement - Dalmia, Coromandel or Zuari or equivalent</span>
                                                        </li>
                                                        <li>
                                                            <span >M sand for Blockwork &amp; Plastering</span>
                                                        </li>
                                                        <li>
                                                            <span >RCC Design Mix - As per the structural designer's recommendation</span>
                                                        </li>
                                                        <li>
                                                            <span >Dr.Fixit Waterproofing</span>
                                                        </li>
                                                        <li>
                                                            <span >Ceiling Height - 10 feet (Finished Floor level to Finished Floor level)</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card border-0">
                                            <div class="card-header p-0 border-0" id="headingThree">
                                                <h2 class="mb-0">
                                                    <button type="button" class="btn btn-link py-0 w-100 collapsed position-relative pack-btn" data-toggle="collapse" data-target="#collapseThree">
                                                        Kitchen
                                                        <i class="fa fa-plus package-fa" ></i>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                                <div class="card-body">
                                                    <ul>
                                                        <li>
                                                            <span >Ceramic Wall Tiles, 2 feet above kitchen slab - Upto Rs.35 per Sqft</span>
                                                        </li>
                                                        <li>
                                                            <span >Main Sink Faucet - Upto Rs.1300</span>
                                                        </li>
                                                        <li>
                                                            <span >Any Other Faucet &amp; Accessories - ISI Marked</span>
                                                        </li>
                                                        <li>
                                                            <span >Kitchen Sink - Stainless Steel Single Sink worth Rs.3000</span>
                                                        </li>
                                                        <li>
                                                            <span >Kitchen Granite upto 38 mm thick - Upto Rs.80 per Sqft</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card border-0">
                                            <div class="card-header p-0 border-0" id="headingFour">
                                                <h2 class="mb-0">
                                                    <button type="button" class="btn btn-link py-0 w-100 collapsed position-relative pack-btn" data-toggle="collapse" data-target="#collapseFour">
                                                        Bathroom
                                                        <i class="fa fa-plus package-fa" ></i>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                                <div class="card-body">
                                                    <ul>
                                                        <li>
                                                            <span >Bathroom Ceramic Wall Tiles with 7 feet height - Upto</span> Rs.35
                                                            <span > per Sqft</span>
                                                        </li>
                                                        <li>
                                                            <span >Sanitary ware &amp; CP Fittings - Upto Rs.30000 per 1000 sqft of construction.</span>
                                                        </li>
                                                        <li>
                                                            <span >CPVC Pipe - Astral or equivalent</span>
                                                        </li>
                                                        <li>
                                                            <span >Bathroom Accessories - EWC, Health Faucet, Wash Basin with accessories, 2 in 1 wall mixer, Overhead Shower.</span>
                                                        </li>
                                                        <li>
                                                            <span >Bathroom doors - Waterproof flush doors</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card border-0">
                                            <div class="card-header p-0 border-0" id="headingFive">
                                                <h2 class="mb-0">
                                                    <button type="button" class="btn btn-link py-0 w-100 collapsed position-relative pack-btn" data-toggle="collapse" data-target="#collapseFive">
                                                        Door & Windows
                                                        <i class="fa fa-plus package-fa" ></i>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                                                <div class="card-body">
                                                    <ul>
                                                        <li>
                                                            <span >Main Door - Flush Door With Veneer. Sal wood frame of 5 inch by 3 inch, worth Rs.13000 including fixtures</span>
                                                        </li>
                                                        <li>
                                                            <span >Internal Doors - Membrane doors / Flush Door with Laminates upto Rs8000 including fixtures. Door Frames of Sal Wood 4 inch by 2.5 inch</span>
                                                        </li>
                                                        <li>
                                                            <span >Windows - Aluminium Windows with glass shutters and mesh shutters(3 track with 1 mesh)</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card border-0">
                                            <div class="card-header p-0 border-0" id="headingSix">
                                                <h2 class="mb-0">
                                                    <button type="button" class="btn btn-link py-0 w-100 collapsed position-relative pack-btn" data-toggle="collapse" data-target="#collapseSix">
                                                        Painting
                                                        <i class="fa fa-plus package-fa" ></i>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
                                                <div class="card-body">
                                                    <ul>
                                                        <li>
                                                            <span >Interior Painting - JK Putty + Tractor Emulsion or equivalent</span>
                                                        </li>
                                                        <li>
                                                            <span >Exterior Painting - Asian Primer + Ace Exterior Emulsion Paint or equivalent</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card border-0">
                                            <div class="card-header p-0 border-0" id="headingSeven">
                                                <h2 class="mb-0">
                                                    <button type="button" class="btn btn-link py-0 w-100 collapsed position-relative pack-btn" data-toggle="collapse" data-target="#collapseSeven">
                                                        Flooring
                                                        <i class="fa fa-plus package-fa" ></i>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordionExample">
                                                <div class="card-body">
                                                    <ul>
                                                        <li>
                                                            <span >Living and Dining Flooring - Tiles of value upto Rs.40 per sqft</span>
                                                        </li>
                                                        <li>
                                                            <span >Rooms and Kitchen Flooring - Tiles or Granite or Wooden flooring upto Rs.40 per sqft</span>
                                                        </li>
                                                        <li>
                                                            <span >Balcony and Open Areas Flooring - Anti-skid tiles of value upto Rs35 per sqft</span>
                                                        </li>
                                                        <li>
                                                            <span >Staircase Flooring - Sadarahalli Granite or Marble of value upto&nbsp;&nbsp;</span>
                                                        </li>
                                                        <li>
                                                            <span >70 per sqft</span>
                                                        </li>
                                                        <li>
                                                            <span >Parking Tiles - Anti-skid tiles of value upto 40 per sqft</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card border-0">
                                            <div class="card-header p-0 border-0" id="headingEight">
                                                <h2 class="mb-0">
                                                    <button type="button" class="btn btn-link py-0 w-100 collapsed position-relative pack-btn" data-toggle="collapse" data-target="#collapseEight">
                                                        Electrical
                                                        <i class="fa fa-plus package-fa" ></i>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample">
                                                <div class="card-body">
                                                    <ul>
                                                        <li>
                                                            <span >Living and Dining Flooring - Tiles of value upto Rs.40 per sqft</span>
                                                        </li>
                                                        <li>
                                                            <span >Rooms and Kitchen Flooring - Tiles or Granite or Wooden flooring upto Rs.40 per sqft</span>
                                                        </li>
                                                        <li>
                                                            <span >Balcony and Open Areas Flooring - Anti-skid tiles of value upto Rs35 per sqft</span>
                                                        </li>
                                                        <li>
                                                            <span >Staircase Flooring - Sadarahalli Granite or Marble of value upto&nbsp;&nbsp;</span>
                                                        </li>
                                                        <li>
                                                            <span >70 per sqft</span>
                                                        </li>
                                                        <li>
                                                            <span >Parking Tiles - Anti-skid tiles of value upto 40 per sqft</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card border-0">
                                            <div class="card-header p-0 border-0" id="headingNine">
                                                <h2 class="mb-0">
                                                    <button type="button" class="btn btn-link py-0 w-100 collapsed position-relative pack-btn" data-toggle="collapse" data-target="#collapseNine">
                                                        Miscellneous
                                                        <i class="fa fa-plus package-fa" ></i>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample">
                                                <div class="card-body">
                                                    <ul>
                                                        <li>
                                                            <span >Overhead Tank - Sintex Double Layered 1000 ltrs</span>
                                                        </li>
                                                        <li>
                                                            <span >Underground Sump - 4000 litres</span>
                                                        </li>
                                                        <li>
                                                            <span >Staircase Railing - MS Railing</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                            <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 py-4">
                                <div class="bs-example">
                                    <div class="accordion" id="accordionExample2">
                                        <div class="card border-0">
                                            <div class="header bg-dark py-3 position-relative" style={{overflow:"hidden"}}>
                                                <h2 class="text-white text-center font-weight-bold display-4 mb-0">
                                                    3
                                                    <img src="image/starwhite.png" class="star-icon" width="50" height="50" />
                                                </h2>
                                               
                                                <div class="clip"></div>
                                            </div>
                                            <div class="card-header p-0 border-0" id="headingOne">
                                                <h2 class="mb-0">
                                                    <button type="button" class="btn btn-link py-0 w-100 collapsed position-relative pack-btn" data-toggle="collapse" data-target="#collapseOne">
                                                        Design & Drawings
                                                        <i class="fa fa-plus package-fa" ></i>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample2">
                                                <div class="card-body">
                                                    <ul>
                                                        <li>
                                                            <span >2D Floor Plan</span>
                                                        </li>
                                                        <li>
                                                            <span >3D Elevation</span>
                                                        </li>
                                                        <li>
                                                            <span >Structural Design</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card border-0">
                                            <div class="card-header p-0 border-0" id="headingTwo">
                                                <h2 class="mb-0">
                                                    <button type="button" class="btn btn-link py-0 w-100 collapsed position-relative pack-btn" data-toggle="collapse" data-target="#collapseTwo">
                                                        Structure
                                                        <i class="fa fa-plus package-fa" ></i>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample2">
                                                <div class="card-body">
                                                    <ul>
                                                        <li>
                                                            <span >Steel - JSW steel or equivalent</span>
                                                        </li>
                                                        <li>
                                                            <span >Aggregates - 20mm &amp; 40mm</span>
                                                        </li>
                                                        <li>
                                                            <span >Blocks - Standard Solid Concrete blocks. 6 inch 4 inch</span>
                                                        </li>
                                                        <li>
                                                            <span >Cement - ACC, Birla or equivalent of Grade - 43 or 53</span>
                                                        </li>
                                                        <li>
                                                            <span >M sand for Blockwork &amp; Plastering</span>
                                                        </li>
                                                        <li>
                                                            <span >RCC Design Mix - As per the structural designer's recommendation</span>
                                                        </li>
                                                        <li>
                                                            <span >Dr.Fixit Waterproofing</span>
                                                        </li>
                                                        <li>
                                                            <span >Ceiling Height - 10 feet (Finished Floor level to Finished Floor level)</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card border-0">
                                            <div class="card-header p-0 border-0" id="headingThree">
                                                <h2 class="mb-0">
                                                    <button type="button" class="btn btn-link py-0 w-100 collapsed position-relative pack-btn" data-toggle="collapse" data-target="#collapseThree">
                                                        Kitchen
                                                        <i class="fa fa-plus package-fa" ></i>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample2">
                                                <div class="card-body">
                                                    <ul>
                                                        <li>
                                                            <span >Ceramic Wall Tiles, 2 feet above kitchen slab - Upto Rs.50 per Sqft</span>
                                                        </li>
                                                        <li>
                                                            <span >Main Sink Faucet - Upto Rs.2000</span>
                                                        </li>
                                                        <li>
                                                            <span >Any Other Faucet &amp; Accessories - Jaquar/Parryware/Hindware</span>
                                                        </li>
                                                        <li>
                                                            <span >Kitchen Sink - Stainless Steel Single Sink worth Rs.6000</span>
                                                        </li>
                                                        <li>
                                                            <span >Kitchen Granite upto 38 mm thick - Upto Rs.120 per Sqft</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card border-0">
                                            <div class="card-header p-0 border-0" id="headingFour">
                                                <h2 class="mb-0">
                                                    <button type="button" class="btn btn-link py-0 w-100 collapsed position-relative pack-btn" data-toggle="collapse" data-target="#collapseFour">
                                                        Bathroom
                                                        <i class="fa fa-plus package-fa" ></i>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample2">
                                                <div class="card-body">
                                                    <ul>
                                                        <li>
                                                            <span >Bathroom Ceramic Wall Tiles with 7 feet height - Upto Rs.50 per Sqft</span>
                                                        </li>
                                                        <li>
                                                            <span >Sanitary ware &amp; CP Fittings - Upto Rs.50000 per 1000 sqft of&nbsp;</span>
                                                        </li>
                                                        <li>
                                                            <span >construction</span>
                                                        </li>
                                                        <li>
                                                            <span >CPVC Pipe - Astral or equivalent</span>
                                                        </li>
                                                        <li>
                                                            <span >Bathroom Accessories - EWC, Health Faucet, Wash Basin with accessories, 2 in 1 wall mixer, Overhead Shower.</span>
                                                        </li>
                                                        <li>
                                                            <span >Bathroom doors - Waterproof flush doors</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card border-0">
                                            <div class="card-header p-0 border-0" id="headingFive">
                                                <h2 class="mb-0">
                                                    <button type="button" class="btn btn-link py-0 w-100 collapsed position-relative pack-btn" data-toggle="collapse" data-target="#collapseFive">
                                                        Door & Windows
                                                        <i class="fa fa-plus package-fa" ></i>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample2">
                                                <div class="card-body">
                                                    <ul>
                                                        <li>
                                                            <span >Main Door - Burma Teak Door with teak frame of 5inch by 3 inch, worth Rs.30000 including fixtures</span>
                                                        </li>
                                                        <li>
                                                            <span >Internal Doors - Membrane doors / Flush Door with Laminates upto&nbsp;</span>
                                                        </li>
                                                        <li>
                                                            <span >Rs.8000 including fixtures. Door Frames of Sal Wood 4 inch by 2.5</span>
                                                        </li>
                                                        <li>
                                                            <span >&nbsp;inch Windows - Aluminium Windows with glass shutters and mesh shutters(3 track with 1 mesh)</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card border-0">
                                            <div class="card-header p-0 border-0" id="headingSix">
                                                <h2 class="mb-0">
                                                    <button type="button" class="btn btn-link py-0 w-100 collapsed position-relative pack-btn" data-toggle="collapse" data-target="#collapseSix">
                                                        Painting
                                                        <i class="fa fa-plus package-fa" ></i>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordionExample2">
                                                <div class="card-body">
                                                    <ul>
                                                        <li>
                                                            <span >Interior Painting - JK Putty + Tractor Emulsion Shyne or equivalent</span>
                                                        </li>
                                                        <li>
                                                            <span >Exterior Painting - Asian Primer + Apex Exterior Emulsion Paint or&nbsp; &nbsp; equivalent</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card border-0">
                                            <div class="card-header p-0 border-0" id="headingSeven">
                                                <h2 class="mb-0">
                                                    <button type="button" class="btn btn-link py-0 w-100 collapsed position-relative pack-btn" data-toggle="collapse" data-target="#collapseSeven">
                                                        Flooring
                                                        <i class="fa fa-plus package-fa" ></i>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordionExample2">
                                                <div class="card-body">
                                                    <ul>
                                                        <li>
                                                            <span >Living and Dining Flooring - Tiles or Granite or Marble of value upto Rs.70 per sqft</span>
                                                        </li>
                                                        <li>
                                                            <span >Rooms and Kitchen Flooring - Tiles or Granite or Wooden flooring upto Rs.60 per sqft</span>
                                                        </li>
                                                        <li>
                                                            <span >Balcony and Open Areas Flooring - Anti-skid tiles of value upto Rs.50 per sqft</span>
                                                        </li>
                                                        <li>
                                                            <span >Staircase Flooring - Sadarahalli Granite or Marble of value upto ₹ 80 per sqft</span>
                                                        </li>
                                                        <li>
                                                            <span >Parking Tiles - Anti-skid tiles of value upto ₹50 per sqft</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card border-0">
                                            <div class="card-header p-0 border-0" id="headingEight">
                                                <h2 class="mb-0">
                                                    <button type="button" class="btn btn-link py-0 w-100 collapsed position-relative pack-btn" data-toggle="collapse" data-target="#collapseEight">
                                                        Electrical
                                                        <i class="fa fa-plus package-fa" ></i>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample2">
                                                <div class="card-body">
                                                    <ul>
                                                        <li>
                                                            <span >Wires - Fireproof wires by Finolex</span>
                                                        </li>
                                                        <li>
                                                            <span >Switches &amp; Sockets - Anchor Roma</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card border-0">
                                            <div class="card-header p-0 border-0" id="headingNine">
                                                <h2 class="mb-0">
                                                    <button type="button" class="btn btn-link py-0 w-100 collapsed position-relative pack-btn" data-toggle="collapse" data-target="#collapseNine">
                                                        Miscellneous
                                                        <i class="fa fa-plus package-fa" ></i>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample2">
                                                <div class="card-body">
                                                    <ul>
                                                        <li>
                                                            <span >Overhead Tank - Sintex Double Layered 1500 ltrs</span>
                                                        </li>
                                                        <li>
                                                            <span >Underground Sump - 6000 litres</span>
                                                        </li>
                                                        <li>
                                                            <span >Staircase Railing - MS Railing</span>
                                                        </li>
                                                        <li>
                                                            <span >Window Grills - Basic MS Grills with Enamel Paint at Rs.110 per sqft</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          
                            <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 py-4">
                                <div class="bs-example">
                                    <div class="accordion" id="accordionExample3">
                                        <div class="card border-0">
                                            <div class="header bg-dark py-3 position-relative" style={{overflow: "hidden"}}>
                                                <h2 class="text-white text-center font-weight-bold display-4 mb-0">
                                                    5
                                                    <img src="image/starwhite.png" class="star-icon" width="50" height="50" />
                                                </h2>
                                               
                                                <div class="clip"></div>
                                            </div>
                                            <div class="card-header p-0 border-0" id="headingOne">
                                                <h2 class="mb-0">
                                                    <button type="button" class="btn btn-link py-0 collapsed w-100 position-relative pack-btn" data-toggle="collapse" data-target="#collapseOne">
                                                        Design & Drawings
                                                        <i class="fa fa-plus package-fa" ></i>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample3">
                                                <div class="card-body">
                                                    <ul>
                                                        <li>
                                                            <span >2D Floor Plan</span>
                                                        </li>
                                                        <li>
                                                            <span >3D Elevation</span>
                                                        </li>
                                                        <li>
                                                            <span >Structural Design</span>
                                                        </li>
                                                        <li>
                                                            <span >Electrical Drawings</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card border-0">
                                            <div class="card-header p-0 border-0" id="headingTwo">
                                                <h2 class="mb-0">
                                                    <button type="button" class="btn btn-link py-0 w-100 collapsed position-relative pack-btn" data-toggle="collapse" data-target="#collapseTwo">
                                                        Structure
                                                        <i class="fa fa-plus package-fa" ></i>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample3">
                                                <div class="card-body">
                                                    <ul>
                                                        <li>
                                                            <span >Steel - JSW steel or equivalent</span>
                                                        </li>
                                                        <li>
                                                            <span >Aggregates - 20mm &amp; 40mm</span>
                                                        </li>
                                                        <li>
                                                            <span >Blocks - Standard Solid Concrete blocks. 6 inch 4 inch</span>
                                                        </li>
                                                        <li>
                                                            <span >Cement - ACC, Birla or equivalent of Grade - 43 or 53</span>
                                                        </li>
                                                        <li>
                                                            <span >M sand for Blockwork &amp; Plastering</span>
                                                        </li>
                                                        <li>
                                                            <span >RCC Design Mix - As per the structural designer's recommendation</span>
                                                        </li>
                                                        <li>
                                                            <span >Dr.Fixit Waterproofing</span>
                                                        </li>
                                                        <li>
                                                            <span >Ceiling Height - 10 feet (Finished Floor level to Finished Floor level)</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card border-0">
                                            <div class="card-header p-0 border-0" id="headingThree">
                                                <h2 class="mb-0">
                                                    <button type="button" class="btn btn-link py-0 w-100 collapsed position-relative pack-btn" data-toggle="collapse" data-target="#collapseThree">
                                                        Kitchen
                                                        <i class="fa fa-plus package-fa" ></i>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample3">
                                                <div class="card-body">
                                                    <ul>
                                                        <li>
                                                            <span >Ceramic Wall Tiles, 2 feet above kitchen slab - Upto Rs.60 per Sqft</span>
                                                        </li>
                                                        <li>
                                                            <span >Main Sink Faucet - Upto Rs.3000</span>
                                                        </li>
                                                        <li>
                                                            <span >Any Other Faucet &amp; Accessories - Jaquar/Parryware/Hindware</span>
                                                        </li>
                                                        <li>
                                                            <span >Kitchen Sink - Stainless Steel Single Sink or Granite finish worth Rs.7000/- (Futura , Carysil)</span>
                                                        </li>
                                                        <li>
                                                            <span >Kitchen Granite upto 38 mm thick - Upto Rs.140 per Sqft</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card border-0">
                                            <div class="card-header p-0 border-0" id="headingFour">
                                                <h2 class="mb-0">
                                                    <button type="button" class="btn btn-link py-0 w-100 collapsed position-relative pack-btn" data-toggle="collapse" data-target="#collapseFour">
                                                        Bathroom
                                                        <i class="fa fa-plus package-fa" ></i>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample3">
                                                <div class="card-body">
                                                    <ul>
                                                        <li>
                                                            <span >Bathroom Ceramic Wall Tiles with 7 feet height - Upto Rs.55 per Sqft</span>
                                                        </li>
                                                        <li>
                                                            <span >Sanitary ware &amp; CP Fittings - Upto Rs.60000 per 1000 sqft of&nbsp;</span>
                                                        </li>
                                                        <li>
                                                            <span >construction</span>
                                                        </li>
                                                        <li>
                                                            <span >CPVC Pipe - Ashirwad / Supreme or equivalent</span>
                                                        </li>
                                                        <li>
                                                            <span >Bathroom Accessories - EWC, Health Faucet, Wash Basin with accessories, 2 in 1 wall mixer, Overhead Shower.</span>
                                                        </li>
                                                        <li>
                                                            <span >Mirror, Soap Dish, Towel Rail - Worth ₹5000 till 1000 ft of construction</span>
                                                        </li>
                                                        <li>
                                                            <span >Bathroom doors - Waterproof flush doors</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card border-0">
                                            <div class="card-header p-0 border-0" id="headingFive">
                                                <h2 class="mb-0">
                                                    <button type="button" class="btn btn-link py-0 w-100 collapsed position-relative pack-btn" data-toggle="collapse" data-target="#collapseFive">
                                                        Door & Windows
                                                        <i class="fa fa-plus package-fa" ></i>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample3">
                                                <div class="card-body">
                                                    <ul>
                                                        <li>
                                                            <span >Main Door - Burma Teak Door with teak frame of 5inch by 3.5 inch, worth Rs.35000 including fixtures</span>
                                                        </li>
                                                        <li>
                                                            <span >Internal Doors - Hardwood Panelled Door upto Rs.11000 including fixtures. Door Frames of Sal Wood 4 inch by 3 inch</span>
                                                        </li>
                                                        <li>
                                                            <span >Windows - White Sal wood/UPVC Windows with glass and mesh shutters(3 track with 1 mesh)</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card border-0">
                                            <div class="card-header p-0 border-0" id="headingSix">
                                                <h2 class="mb-0">
                                                    <button type="button" class="btn btn-link py-0 w-100 collapsed position-relative pack-btn" data-toggle="collapse" data-target="#collapseSix">
                                                        Painting
                                                        <i class="fa fa-plus package-fa" ></i>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordionExample3">
                                                <div class="card-body">
                                                    <ul>
                                                        <li>
                                                            <span >Interior Painting - JK Putty + Apcolite Premium Emulsion or equivalent</span>
                                                        </li>
                                                        <li>
                                                            <span >Exterior Painting - Asian Primer + Apex Exterior Emulsion Paint or equivalent</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card border-0">
                                            <div class="card-header p-0 border-0" id="headingSeven">
                                                <h2 class="mb-0">
                                                    <button type="button" class="btn btn-link py-0 w-100 collapsed position-relative pack-btn" data-toggle="collapse" data-target="#collapseSeven">
                                                        Flooring
                                                        <i class="fa fa-plus package-fa" ></i>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordionExample3">
                                                <div class="card-body">
                                                    <ul>
                                                        <li>
                                                            <span >Living and Dining Flooring - Tiles or Granite or Marble of value upto Rs.100 per sqft</span>
                                                        </li>
                                                        <li>
                                                            <span >Rooms and Kitchen Flooring - Tiles or Granite or Wooden flooring&nbsp;</span>
                                                        </li>
                                                        <li>
                                                            <span >upto Rs.80 per sqft</span>
                                                        </li>
                                                        <li>
                                                            <span >Balcony and Open Areas Flooring - Anti-skid tiles of value upto&nbsp;</span>
                                                        </li>
                                                        <li>
                                                            <span >Rs.60 per sqft</span>
                                                        </li>
                                                        <li>
                                                            <span >Staircase Flooring - Sadarahalli Granite or Marble of value upto&nbsp;</span>
                                                        </li>
                                                        <li>
                                                            <span >Rs. 80 per sqft</span>
                                                        </li>
                                                        <li>
                                                            <span >Parking Tiles - Anti-skid tiles of value upto ₹50 per sqft doors</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card border-0">
                                            <div class="card-header p-0 border-0" id="headingEight">
                                                <h2 class="mb-0">
                                                    <button type="button" class="btn btn-link py-0 w-100 collapsed position-relative pack-btn" data-toggle="collapse" data-target="#collapseEight">
                                                        Electrical
                                                        <i class="fa fa-plus package-fa" ></i>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample3">
                                                <div class="card-body">
                                                    <ul>
                                                        <li>
                                                            <span >Wires - Fireproof wires by Finolex</span>
                                                        </li>
                                                        <li>
                                                            <span >Switches &amp; Sockets - Anchor Roma</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card border-0">
                                            <div class="card-header p-0 border-0" id="headingNine">
                                                <h2 class="mb-0">
                                                    <button type="button" class="btn btn-link py-0 w-100 collapsed position-relative pack-btn" data-toggle="collapse" data-target="#collapseNine">
                                                        Miscellneous
                                                        <i class="fa fa-plus package-fa" ></i>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample3">
                                                <div class="card-body">
                                                    <ul>
                                                        <li>
                                                            <span >Overhead Tank - Sintex Triple Layered 1500 ltrs</span>
                                                        </li>
                                                        <li>
                                                            <span >Underground Sump - 7000 litres</span>
                                                        </li>
                                                        <li>
                                                            <span >Staircase Railing - MS Railings with sal wood hand rail</span>
                                                        </li>
                                                        <li>
                                                            <span >Window Grills - Basic MS Grills with Enamel Paint at Rs.110 per sqft</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                            <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 py-4">
                                <div class="bs-example">
                                    <div class="accordion" id="accordionExample4">
                                        <div class="card border-0">
                                            <div class="header bg-dark py-3 position-relative" style={{overflow: "hidden"}}>
                                                <h2 class="text-white text-center font-weight-bold display-4 mb-0">
                                                    7
                                                    <img src="image/starwhite.png" class="star-icon" width="50" height="50" />
                                                </h2>
                                               
                                                <div class="clip"></div>
                                            </div>
                                            <div class="card-header p-0 border-0" id="headingOne">
                                                <h2 class="mb-0">
                                                    <button type="button" class="btn btn-link py-0 w-100 collapsed position-relative pack-btn" data-toggle="collapse" data-target="#collapseOne">
                                                        Design & Drawings
                                                        <i class="fa fa-plus package-fa" ></i>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample4">
                                                <div class="card-body">
                                                    <ul>
                                                        <li>
                                                            <span >2D Floor Plan</span>
                                                        </li>
                                                        <li>
                                                            <span >3D Elevation</span>
                                                        </li>
                                                        <li>
                                                            <span >Structural Design</span>
                                                        </li>
                                                        <li>
                                                            <span >Electrical Drawings</span>
                                                        </li>
                                                        <li>
                                                            <span >Plumbing Drawings</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card border-0">
                                            <div class="card-header p-0 border-0" id="headingTwo">
                                                <h2 class="mb-0">
                                                    <button type="button" class="btn btn-link py-0 w-100 collapsed position-relative pack-btn" data-toggle="collapse" data-target="#collapseTwo">
                                                        Structure
                                                        <i class="fa fa-plus package-fa" ></i>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample4">
                                                <div class="card-body">
                                                    <ul>
                                                        <li>
                                                            <span >Steel - JSW steel or equivalent</span>
                                                        </li>
                                                        <li>
                                                            <span >Aggregates - 20mm &amp; 40mm</span>
                                                        </li>
                                                        <li>
                                                            <span >Blocks - Standard Solid Concrete blocks. 6 inch 4 inch</span>
                                                        </li>
                                                        <li>
                                                            <span >Cement - ACC, Birla or equivalent of Grade - 43 or 53</span>
                                                        </li>
                                                        <li>
                                                            <span >M sand for Blockwork &amp; Plastering</span>
                                                        </li>
                                                        <li>
                                                            <span >RCC Design Mix - As per the structural designer's recommendation</span>
                                                        </li>
                                                        <li>
                                                            <span >Dr.Fixit Waterproofing</span>
                                                        </li>
                                                        <li>
                                                            <span >Ceiling Height - 10 feet (Finished Floor level to Finished Floor level)</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card border-0">
                                            <div class="card-header p-0 border-0" id="headingThree">
                                                <h2 class="mb-0">
                                                    <button type="button" class="btn btn-link py-0 w-100 collapsed position-relative pack-btn" data-toggle="collapse" data-target="#collapseThree">
                                                        Kitchen
                                                        <i class="fa fa-plus package-fa" ></i>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample4">
                                                <div class="card-body">
                                                    <ul>
                                                        <li>
                                                            <span >Ceramic Wall Tiles, 2 feet above kitchen slab - Upto Rs.70 per Sqft</span>
                                                        </li>
                                                        <li>
                                                            <span >Main Sink Faucet - Upto Rs.3500</span>
                                                        </li>
                                                        <li>
                                                            <span >Any Other Faucet &amp; Accessories - Jaquar/Parryware/Hindware</span>
                                                        </li>
                                                        <li>
                                                            <span >Kitchen Sink - Stainless Steel Single Sink or Granite finish worth Rs.8000/- (Futura , Carysil)</span>
                                                        </li>
                                                        <li>
                                                            <span >Kitchen Granite upto 38 mm thick - Upto Rs.160 per Sqft</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card border-0">
                                            <div class="card-header p-0 border-0" id="headingFour">
                                                <h2 class="mb-0">
                                                    <button type="button" class="btn btn-link py-0 w-100 collapsed position-relative pack-btn" data-toggle="collapse" data-target="#collapseFour">
                                                        Bathroom
                                                        <i class="fa fa-plus package-fa" ></i>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample4">
                                                <div class="card-body">
                                                    <ul>
                                                        <li>
                                                            <span >Bathroom Ceramic Wall Tiles with 7 feet height - Upto Rs.65 per Sqft</span>
                                                        </li>
                                                        <li>
                                                            <span >Sanitary ware &amp; CP Fittings - Upto Rs.70000 per 1000 sqft of construc.</span>
                                                        </li>
                                                        <li>
                                                            <span >CPVC Pipe - Ashirwad / Supreme or equivalent</span>
                                                        </li>
                                                        <li>
                                                            <span >Bathroom Accessories - EWC, Health Faucet, Wash Basin with accessories, 2 in 1 wall mixer, Overhead Shower.</span>
                                                        </li>
                                                        <li>
                                                            <span >Mirror, Soap Dish, Towel Rail - Worth ₹7000 till 1000 ft of construction</span>
                                                        </li>
                                                        <li>
                                                            <span >Bathroom doors - Waterproof flush doors</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card border-0">
                                            <div class="card-header p-0 border-0" id="headingFive">
                                                <h2 class="mb-0">
                                                    <button type="button" class="btn btn-link py-0 w-100 collapsed position-relative pack-btn" data-toggle="collapse" data-target="#collapseFive">
                                                        Door & Windows
                                                        <i class="fa fa-plus package-fa" ></i>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample4">
                                                <div class="card-body">
                                                    <ul>
                                                        <li>
                                                            <span >Main Door - Burma Teak Door with teak frame of 5inch by 3.5 inch, worth Rs.40000 including fixtures</span>
                                                        </li>
                                                        <li>
                                                            <span >Internal Doors - Hardwood Panelled Door upto Rs.11000 including fixtures. Door Frames of Sal Wood 4 inch by 3 inch</span>
                                                        </li>
                                                        <li>
                                                            <span >Windows - White Sal wood/UPVC Windows with glass and mesh shutters(3 track with 1 mesh)</span>
                                                        </li>
                                                        <li>
                                                            <span >Pooja Room Door - Burma Teak along with teak frame of 5inch by 2.5 inch, worth ₹25000</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card border-0">
                                            <div class="card-header p-0 border-0" id="headingSix">
                                                <h2 class="mb-0">
                                                    <button type="button" class="btn btn-link py-0 w-100 collapsed position-relative pack-btn" data-toggle="collapse" data-target="#collapseSix">
                                                        Painting
                                                        <i class="fa fa-plus package-fa" ></i>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordionExample4">
                                                <div class="card-body">
                                                    <ul>
                                                        <li>
                                                            <span >nterior Painting - JK Putty + Apcolite Premium Emulsion or equivalent</span>
                                                        </li>
                                                        <li>
                                                            <span >Exterior Painting - Asian Primer + Apex Exterior Emulsion Paint or equivalent</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card border-0">
                                            <div class="card-header p-0 border-0" id="headingSeven">
                                                <h2 class="mb-0">
                                                    <button type="button" class="btn btn-link py-0 w-100 collapsed position-relative pack-btn" data-toggle="collapse" data-target="#collapseSeven">
                                                        Flooring
                                                        <i class="fa fa-plus package-fa" ></i>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordionExample4">
                                                <div class="card-body">
                                                    <ul>
                                                        <li>
                                                            <span >Living and Dining Flooring - Tiles or Granite or Marble of value upto&nbsp;</span>
                                                        </li>
                                                        <li>
                                                            <span >Rs.140 per sqft</span>
                                                        </li>
                                                        <li>
                                                            <span >Rooms and Kitchen Flooring - Tiles or Granite or Wooden flooring upto Rs.120 per sqft</span>
                                                        </li>
                                                        <li>
                                                            <span >Balcony and Open Areas Flooring - Anti-skid tiles of value upto Rs.65</span>
                                                        </li>
                                                        <li>
                                                            <span >per sqft</span>
                                                        </li>
                                                        <li>
                                                            <span >Staircase Flooring - Sadarahalli Granite or Marble of value upto 100&nbsp;</span>
                                                        </li>
                                                        <li>
                                                            <span >per sqft</span>
                                                        </li>
                                                        <li>
                                                            <span >Parking Tiles - Anti-skid tiles of value upto ₹60 per sqft doors</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card border-0">
                                            <div class="card-header p-0 border-0" id="headingEight">
                                                <h2 class="mb-0">
                                                    <button type="button" class="btn btn-link py-0 w-100 collapsed position-relative pack-btn" data-toggle="collapse" data-target="#collapseEight">
                                                        Electrical
                                                        <i class="fa fa-plus package-fa" ></i>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample4">
                                                <div class="card-body">
                                                    <ul>
                                                        <li>
                                                            <span >Wires - Fireproof wires by Finolex</span>
                                                        </li>
                                                        <li>
                                                            <span >Switches &amp; Sockets - GM Modular / Legrand</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card border-0">
                                            <div class="card-header p-0 border-0" id="headingNine">
                                                <h2 class="mb-0">
                                                    <button type="button" class="btn btn-link py-0 w-100 collapsed position-relative pack-btn" data-toggle="collapse" data-target="#collapseNine">
                                                        Miscellneous
                                                        <i class="fa fa-plus package-fa" ></i>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordionExample4">
                                                <div class="card-body">
                                                    <ul>
                                                        <li>
                                                            <span >Overhead Tank - Sintex Triple Layered 2000 ltrs</span>
                                                        </li>
                                                        <li>
                                                            <span >Underground Sump - 7000 litres</span>
                                                        </li>
                                                        <li>
                                                            <span >Staircase Railing - MS Railings with teak wood hand rail</span>
                                                        </li>
                                                        <li>
                                                            <span >Window Grills - Windows MS Grills with Enamel Paint as per client&nbsp;</span>
                                                        </li>
                                                        <li>
                                                            <span >requirement at ₹130 per sqft</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </section>
      
        );
    }
}

export default Packages;