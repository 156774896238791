
import logo from './favicon.png';
import './App.css';
import Estimator from './Estimator/Estimator';
import ThemeSwitcher from './ThemeSwitcher/ThemeSwitcher';
import Packages from './Packages/Packages';

function App() {
  return (
    <div className="App container-fluid" style={{padding:"0px",margin:"0px"}}>
      <header className="container-fluid" style={{padding:"0px",margin:"0px"}}>
        {/* */}
  <nav className="navbar navbar-expand-sm navbar-light bg-light bgyellow" >
    <a className="navbar-brand" href="#">
    {/* <img src={logo} className="App-logo" alt="logo" /> */}
    <img src="image/logo_1.png" className="img-fluid logo" />
    </a>
    <button className="navbar-toggler d-lg-none" type="button" data-toggle="collapse" data-target="#collapsibleNavId" aria-controls="collapsibleNavId"
        aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="collapsibleNavId">
        <ul className="navbar-nav ml-auto mt-2 mt-lg-0 ">
        <li className="nav-item active">
                <a className="nav-link" href="#costestimator-section">Home <span className="sr-only">(current)</span></a>
            </li>
            <li className="nav-item active">
                <a className="nav-link" href="#costestimator-section">Cost Estimator <span className="sr-only">(current)</span></a>
            </li>
            <li className="nav-item">
                <a className="nav-link" href="#packages-section">Packages</a>
            </li>
            {/* <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="dropdownId" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Dropdown</a>
                <div className="dropdown-menu" >
                    <a className="dropdown-item" href="#estimator">Action 1</a>
                    <a className="dropdown-item" href="#estimator">Action 2</a>
                </div>
            </li> */}
        </ul>
        {/* <form className="form-inline my-2 my-lg-0">
            <input className="form-control mr-sm-2" type="text" placeholder="Search"/>
            <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
        </form> */}
    </div>
    </nav>   
      </header>
      <section className="container-fluid" id='estimator'>
      <article>
			<header>
				{/* <h2>ESTIMATOR</h2> */}
			
        <Estimator/>
        {/* <div className="container-fluid">
        <p className="pull-right"><a  href="#" >Get It by Email</a> - <a href="#comments">Call Us</a></p>
        </div> */}
        <Packages/>
        {/* <ThemeSwitcher/> */}
			</header>
			<p> </p>
      </article>
      </section>
    </div>
  );
}

export default App;
